import React from "react";
import { Link, useParams } from "react-router-dom";
function ServiceDetailsWrap() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  let { servicename } = useParams();

  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/blog-thumnail.jpg"}
                  alt="images service"
                />
                <h3>
                  <i>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/icons/service-details-icon.png"
                      }
                      alt="service icon images"
                    />
                  </i>
                  BlockChain Technology
                </h3>
                <p>
                  We are a team of experienced professionals who specialize in
                  providing innovative blockchain solutions to businesses and
                  organizations of all sizes. Our mission is to empower our
                  clients with the transformative power of blockchain
                  technology, enabling them to achieve their goals in a more
                  secure, transparent, and efficient manner.
                </p>
                <p>
                  Our services include blockchain consulting, development,
                  implementation, and maintenance, all tailored to meet the
                  unique needs of each of our clients. We work with a variety of
                  blockchain platforms, including Ethereum, Hyperledger, and
                  Corda, and we have the expertise to develop custom blockchain
                  solutions that are scalable and secure.
                </p>
                <p>
                  At our company, we believe that blockchain technology has the
                  potential to transform the way businesses operate, and we are
                  committed to helping our clients unlock its full potential.
                  Contact us today to learn more about how we can help you
                  harness the power of blockchain technology to achieve your
                  business objectives.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-widget">
                <h4>Category</h4>
                <ul className="category">
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      AI & IOT
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Cyber Security
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      DevOps & Automation
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Cloud Services
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      BlockChain Technology
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Staffing or Recruitment
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sidebar-banner">
                <img
                  src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"}
                  alt="Banner images"
                />
                <div className="banner-inner">
                  <h3>
                    Any Project <span>Call Now.</span>
                    <img
                      className="angle"
                      src={process.env.PUBLIC_URL + "/img/arrow-angle.png"}
                      alt=""
                    />
                  </h3>
                  <a href="tel:11231231234">+1-123-123-1234</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap;
