import React from "react";
import ClientTestimonial from "./ClientTestimonial";
import PriceCardWrap from "./PriceCardWrap";
import ServiceCardWrap from "./ServiceCardWrap";
import Enquiry from "./Enquiry";

function ServiceWrap() {
  return (
    <>
      <ServiceCardWrap />
      <Enquiry/>
      <ClientTestimonial />
      {/* <PriceCardWrap /> */}
    </>
  );
}

export default ServiceWrap;
