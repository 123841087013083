import React from "react";
import Breadcrumb from "../../../common/Breadcrumb";

function cyber() {
  return (
    <>
      <Breadcrumb pageName="Careers > Cyber Security" pageTitle="Careers " />
      <div className="project-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Cybersecurity Analyst</span>
              <p>
                We are seeking a skilled Cybersecurity Analyst to join our team
                and assist in providing cybersecurity solutions for our clients.
                The ideal candidate will have 2+ years of experience in
                cybersecurity, with a strong understanding of risk management,
                threat detection, and incident response.
              </p>
              <h2>Roles and Responsibilities:</h2>
              <ul className="my-3">
                <li>
                  1.Conduct security assessments and vulnerability testing
                </li>
                <li>
                  2.Develop and implement security policies and procedures
                </li>
                <li>3.Manage the hiring process from start to finish</li>
                <li>4.Investigate and respond to security incidents</li>
              </ul>
              <div className="cmn-btn my-3">
                <div className="line-1" />
                <div className="line-2" />
                <a href='mailto:info@viasdlc.com'>Apply now</a>
              </div>
            </div>
          </div>
          {/* <ProjectCardFilter/> */}
        </div>
      </div>
    </>
  );
}

export default cyber;
