import React from "react";

function HistoryArea() {
  return (
    <>
      <section className="history-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>History</span>
              <h2>ViaSDLC History</h2>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/timeline-1.jpg"}
                  alt="history-img"
                />
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2022</span>
                  <h4>We Are Open Our Office</h4>
                  <p>
                    Founded in 2022 by a group of tech enthusiasts, the company
                    started as a small software development firm specializing in
                    custom applications. Over the years, they expanded their
                    offerings to include Cloud services, and later added AI and
                    DevOps services to their portfolio.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2022</span>
                  <h4>We Work Hard for our Customers</h4>
                  <p>
                    In 2022, the company began exploring the potential of
                    Blockchain technology, and became one of the early adopters
                    of this disruptive technology. By 2022, the company had
                    established itself as a leading provider of Blockchain
                    solutions, and had helped several businesses leverage the
                    technology for secure and transparent transactions.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/timeline-2.jpg"}
                  alt="history-img"
                />
              </div>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/timeline-3.jpg"}
                  alt="history-img"
                />
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2023</span>
                  <h4>We Are Success And Win</h4>
                  <p>
                    With the growing demand for cybersecurity solutions, the
                    company expanded its offerings to include a range of
                    cybersecurity services, such as network security, data
                    encryption, and vulnerability assessments.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="500ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2023</span>
                  <h4>Many Award Win</h4>
                  <p>
                    Today, the company has become a one-stop-shop for businesses
                    seeking innovative IT solutions. With a focus on staying
                    ahead of the curve on emerging technologies, the company
                    continues to help businesses across industries modernize and
                    transform their operations.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="500ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/timeline-4.jpg"}
                  alt="history-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HistoryArea;
