import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "./components/App";
import MainLayout from "./components/layout/MainLayout";
import About from "./components/page/about/About";
import BlogSidebar from "./components/page/blog/BlogSidebar";
import BlogDetails from "./components/page/BlogDetails/BlogDetails";
import BlogStandard from "./components/page/BlogStandard/BlogStandard";
import Contact from "./components/page/contact/Contact";
import ErrorPage from "./components/page/error/ErrorPage";
import Careers from "./components/page/careers/Careers";
import Faq from "./components/page/faq/Faq";
import Pricing from "./components/page/pricing/Pricing";
import Project from "./components/page/project/Project";
import ProjectDetails from "./components/page/ProjectDetails/ProjectDetails";
import Service from "./components/page/service/Service";
import AI from "./components/page/ServiceDetails/Ai/ServiceDetails";
import Cyber from "./components/page/ServiceDetails/Cyber/ServiceDetails";
import Cloud from "./components/page/ServiceDetails/Cloud/ServiceDetails";
import Blockchain from "./components/page/ServiceDetails/blockchain/ServiceDetails";
import staffing from "./components/page/ServiceDetails/staffing/ServiceDetails";
import Team from "./components/page/team/Team";
import "./index.css";
import cyber from "./components/page/careers/cyber/cyber";
import Dev from "./components/page/careers/devops/dev";
import Staff from "./components/page/careers/staffing/staff";
import Block from "./components/page/careers/block/block";




//Default Warniing Error Hide
console.log = console.warn = console.error = () => {};

/*
=>version : 0.1
=>Event : Rendering al content to web
=>Action: define all routes and page
@return HTML
*/

function Root() {
  return (
    <>
      <BrowserRouter basename="/">
        <Switch>
          {/*main layout*/}
          <Route exact path="/" component={MainLayout} />
          secound layout
          {/* <Route exact path="/index2" component={HomePageTwo} /> */}
          {/* all inner page load layout component */}
          <Layout>
            {/* <Route
          exact
          path={`${process.env.PUBLIC_URL}/about-us`}
          component={AboutPage}
        /> */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/about`}
              component={About}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              component={Contact}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/error`}
              component={ErrorPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/careers`}
              component={Careers}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/careers/cyber`}
              component={cyber}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/careers/dev`}
              component={Dev}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/careers/staff`}
              component={Staff}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/careers/block`}
              component={Block}
            />










            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service`}
              component={Service}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service/Ai & IOT`}
              component={AI}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service/Cloud Services`}
              component={Cloud}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service/Cyber Security`}
              component={Cyber}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service/BlockChain Technology`}
              component={Blockchain}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service/DevOps & Automation`}
              component={Dev}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service/Staffing or Recruitment`}
              component={staffing}
            />
            {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/project-details`}
              component={ProjectDetails}
            /> */}
            
            {/* 
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/team`}
          component={TeamPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/feedback`}
          component={FeedbackPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/faq`}
          component={FaqPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/error`}
          component={ErrorPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/blog-grid`}
          component={BlogGridPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/blog-sidebar`}
          component={BlogSidebarPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/blog-details`}
          component={BlogDetailsPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/project`}
          component={ProjectPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/project-details`}
          component={ProjectDetailsPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/service`}
          component={ServicePage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/service-details`}
          component={ServiceDetailsPage}
        /> */}
          </Layout>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default Root;

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);
