import React from "react";
import Breadcrumb from "../../../common/Breadcrumb";

function block() {
  return (
    <>
      <Breadcrumb pageName="Careers > Blockchain" pageTitle="Careers " />
      <div className="project-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Blockchain Developer</span>
              <p>
                We are seeking a skilled Blockchain Developer to join our team
                and assist in developing and implementing blockchain-based
                solutions for our clients. The ideal candidate will have 2+
                years of experience in developing decentralized applications,
                smart contracts, and building on blockchain platforms such as
                Ethereum and Hyperledger.
              </p>
              <h2>Roles and Responsibilities:</h2>
              <ul className="my-3">
                <li>1.Design and develop blockchain-based solutions</li>
                <li>
                  2.Collaborate with cross-functional teams to integrate
                  blockchain with existing systems
                </li>
                <li>3.Write and test smart contracts</li>
                <li>4.Provide technical guidance and support to clients</li>
              </ul>
              <div className="cmn-btn my-3">
                <div className="line-1" />
                <div className="line-2" />
                <a href='mailto:info@viasdlc.com'>Apply now</a>
              </div>
            </div>
          </div>
          {/* <ProjectCardFilter/> */}
        </div>
      </div>
    </>
  );
}

export default block;
