import React from "react";
import { Link, useParams } from "react-router-dom";

function ServiceDetailsWrap() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/blog-thumnail.jpg"}
                  alt="images service"
                />
                <h3>
                  <i>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/icons/service-details-icon.png"
                      }
                      alt="service icon images"
                    />
                  </i>
                  AI and IOT
                </h3>
                <p>
                  We offer cutting-edge AI/IoT services to help businesses and
                  individuals transform the way they work and live. With the
                  convergence of artificial intelligence and the internet of
                  things, we are at the forefront of innovation and are
                  committed to providing our clients with the most advanced
                  solutions available today. Our team of experts is dedicated to
                  delivering customized solutions that leverage the power of AI
                  and IoT to improve productivity, increase efficiency, and
                  enhance the overall user experience. From smart homes to
                  industrial automation, our services span a wide range of
                  industries and applications.
                </p>
                <p>
                  We pride ourselves on our commitment to excellence, and our
                  ability to work closely with our clients to understand their
                  unique needs and requirements. Whether you are looking to
                  streamline your operations, improve your customer experience,
                  or enhance your products and services, we have the expertise
                  and experience to help you achieve your goals.
                </p>
                <p>
                  At our company, we are passionate about the potential of AI
                  and IoT to transform the world, and we are dedicated to
                  helping our clients realize that potential. We invite you to
                  explore our services and learn more about how we can help you
                  achieve your business objectives.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-widget">
                <h4>Category</h4>
                <ul className="category">
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      AI & IOT
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Cyber Security
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      DevOps & Automation
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Cloud Services
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      BlockChain Technology
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Staffing or Recruitment
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sidebar-banner">
                <img
                  src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"}
                  alt="Banner images"
                />
                <div className="banner-inner">
                  <h3>
                    Any Project <span>Call Now.</span>
                    <img
                      className="angle"
                      src={process.env.PUBLIC_URL + "/img/arrow-angle.png"}
                      alt=""
                    />
                  </h3>
                  <a href="tel:11231231234">+1-123-123-1234</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap;
