import React from "react";
import ServiceCard from "./ServiceCard";

function ServiceCardWrap() {
  return (
    <>
      <div className="services-grid sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Our Solutions</span>
              <h2>Services</h2>
              <p>
                We provide a comprehensive range of services that can help
                businesses modernize and transform their operations.
              </p>
            </div>
          </div>
          <div className="row g-4">
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={1}
                image="/img/icons/service-icon-1.png"
                title="AI & IOT"
                details="Our company is at the forefront of the AI/IoT revolution,
                leveraging the latest technologies to help businesses
                optimize their operations, improve customer experiences, and
                drive growth."
              />
            </div>
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={2}
                image="/img/icons/service-icon-2.png"
                title="Cyber Security"
                details="We specialize in Cyber Security provides products and
                services to protect organizations from digital threats such
                as hacking, data breaches, and cyber attacks."
              />
            </div>
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={3}
                image="/img/icons/service-icon-3.png"
                title="DevOps & Automation"
                details="We help businesses to streamline their software development
                and IT operations processes, improving efficiency and
                reducing time-to-market."
              />
            </div>
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={4}
                image="/img/icons/service-icon-4.png"
                title="Cloud Services"
                details=" We offer a range of innovative and reliable cloud-based
                solutions to help businesses of all sizes achieve their
                goals."
              />
            </div>
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={5}
                image="/img/icons/service-icon-5.png"
                title="BlockChain Technology"
                details="We offer a range of services, including blockchain
                development, consulting, and implementation services."
              />
            </div>
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={6}
                image="/img/icons/service-icon-6.png"
                title="Staffing or Recruitment"
                details="We specialize in helping businesses find the right talent to
                meet their needs."
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceCardWrap;
