import React from 'react'

function WhyChooseUs() {
  return (
    <>
      <section className="why-choose sec-mar wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="why-choose-left">
                <div className="choose-banner1">
                  <img src={process.env.PUBLIC_URL + "/img/why-1.jpg"} alt="why-choose images" />
                </div>
                <div className="choose-banner2">
                  <img src={process.env.PUBLIC_URL + "/img/why-2.jpg"} alt="why-choose images" />
                  <img src={process.env.PUBLIC_URL + "/img/why-3.jpg"} alt="why-choose images" />
                </div>
                <div className="years">
                  {/* <h5>20+</h5> */}
                  {/* <span>Years</span> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-right">
                <div className="sec-title layout2">
                  <span>Why Choose</span>
                  <h4>Our expertise in cutting-edge technologies, commitment to excellence, and personalized approach to every project.</h4>
                </div>
                <div className="counter-boxes">
                  <div className="count-box">
                    <span className="counter">50</span><sup>+</sup>
                    <h5>Project Completed</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">40</span><sup>+</sup>
                    <h5>Satisfied Clients</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">12</span><sup>+</sup>
                    <h5>Expert Teams</h5>
                  </div>
                </div>
                <p>Our team of experienced professionals has a proven track record of delivering results for businesses across industries. We pride ourselves on our ability to stay ahead of the curve on the latest technologies, ensuring that our clients always have access to the best solutions for their needs.</p>
                <div className="buttons-group">
                  <span>24/7 Support</span>
                  <span>Unique Design</span>
                  <span>Clean Code Develope</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default WhyChooseUs