import React from "react";
import { Link, useParams } from "react-router-dom";
function ServiceDetailsWrap() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  let { servicename } = useParams();

  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/blog-thumnail.jpg"}
                  alt="images service"
                />
                <h3>
                  <i>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/icons/service-details-icon.png"
                      }
                      alt="service icon images"
                    />
                  </i>
                  Cloud Service
                </h3>
                <p>
                  We are leading provider of cloud services. We specialize in
                  delivering flexible, scalable, and cost-effective cloud
                  solutions to help businesses of all sizes grow and succeed.
                  Our cloud services are designed to enable organizations to
                  modernize their IT infrastructure, optimize their operations,
                  and enhance their customer experience.
                </p>
                <p>
                  Our cloud platform is built on industry-leading technologies
                  and offers a wide range of services, including computing,
                  storage, networking, databases, analytics, machine learning,
                  security, and more. With our cloud services, businesses can
                  easily deploy and manage applications, store and process data,
                  and access advanced technologies without the need for
                  extensive infrastructure investments.
                </p>
                <p>
                  Whether you're a small startup or a large enterprise, our
                  cloud services are designed to meet your unique needs and help
                  you achieve your goals. Contact us today to learn more about
                  how our cloud solutions can help your business thrive in the
                  digital age.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-widget">
                <h4>Category</h4>
                <ul className="category">
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      AI & IOT
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Cyber Security
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      DevOps & Automation
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Cloud Services
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      BlockChain Technology
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Staffing or Recruitment
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sidebar-banner">
                <img
                  src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"}
                  alt="Banner images"
                />
                <div className="banner-inner">
                  <h3>
                    Any Project <span>Call Now.</span>
                    <img
                      className="angle"
                      src={process.env.PUBLIC_URL + "/img/arrow-angle.png"}
                      alt=""
                    />
                  </h3>
                  <a href="tel:11231231234">+1-123-123-1234</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap;
