import React from "react";
import Breadcrumb from "../../../common/Breadcrumb";

function Dev() {
  return (
    <>
      <Breadcrumb pageName="Careers > Dev" pageTitle="Careers " />
      <div className="project-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>DevOps Engineer</span>
              <p>
                We are seeking a skilled DevOps Engineer to join our team and
                help automate and streamline our clients' development and
                operations processes. The ideal candidate will have 3+ years of
                experience in DevOps, with a strong understanding of continuous
                integration/continuous deployment (CI/CD) and infrastructure as
                code (IaC).
              </p>
              <h2>Roles and Responsibilities:</h2>
              <ul className="my-3">
                <li>1.Develop and maintain CI/CD pipelines</li>
                <li>
                  2.Automate deployment, scaling, and monitoring of systems
                </li>
                <li>
                  3.Implement infrastructure as code using tools such as
                  Terraform and Ansible
                </li>
                <li>
                  4.Collaborate with development and operations teams to improve
                  processes and systems.
                </li>
              </ul>
              <div className="cmn-btn my-3">
                <div className="line-1" />
                <div className="line-2" />
                <a href='mailto:info@viasdlc.com'>Apply now</a>
              </div>
            </div>
          </div>
          {/* <ProjectCardFilter/> */}
        </div>
      </div>
    </>
  );
}

export default Dev;
