import React from "react";
import Breadcrumb from "../../../common/Breadcrumb";

function staff() {
  return (
    <>
      <Breadcrumb pageName="Careers > Staffing" pageTitle="Careers " />
      <div className="project-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Staffing Specialist </span>
              <p>
                We are looking for an experienced Staffing Specialist to join
                our team and help manage the hiring process for our clients. The
                ideal candidate will have 3+ years of experience in recruitment,
                with a strong understanding of the IT industry and its talent
                requirements.
              </p>
              <h2>Roles and Responsibilities:</h2>
              <ul className="my-3">
                <li>1.Source and recruit candidates for open positions</li>
                <li>2.Screen resumes and conduct interviews</li>
                <li>3.Manage the hiring process from start to finish</li>
                <li>
                  4.Build relationships with clients and provide ongoing support
                </li>
              </ul>
              <div className="cmn-btn my-3">
                <div className="line-1" />
                <div className="line-2" />
                <a href='mailto:info@viasdlc.com'>Apply now</a>
              </div>
            </div>
          </div>
          {/* <ProjectCardFilter/> */}
        </div>
      </div>
    </>
  );
}

export default staff;

function Content() {
  return <></>;
}
