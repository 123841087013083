import React from "react";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import { Link } from "react-router-dom";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);
function ClientTestimonial() {
    const testimonial={
        loop: true,
        slidesPerView: 2,
        centeredSlides: true,
        spaceBetween: 30,
        slideToClickedSlide: true,

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 2,
            },
            1400: {
                slidesPerView: 2,
            },
            1800: {
                slidesPerView: 2,
            }
        }
    }
  return (
    <>
      <div className="testimonial-area">
        <div className="container-fluid p-0">
          <div className="title-wrap">
            <div className="sec-title white">
              <span>Testimonial</span>
              <h2>Client Say About Us</h2>
              <p>
                Curabitur sed facilisis erat. Vestibulum pharetra eros eget
                fringilla porttitor. on Duis a orci nunc. Suspendisse ac
                convallis sapien, quis commodo libero.
              </p>
            </div>
          </div>
          <Swiper {...testimonial} className="swiper testimonial-slider">
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Martha Maldonado</h5>
                  <span>Executive Chairman</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                  "The AI solutions provided by this IT company have truly
                    transformed our business. We're now able to automate many of
                    our processes, saving us time and increasing efficiency. The
                    team's expertise in cloud technology and cybersecurity has
                    also given us peace of mind, knowing our data is secure in
                    the cloud."
                  </p>
                  <div className="reviewer">
                    <img src={process.env.PUBLIC_URL +"/img/reivewer.jpg"} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Jesmin korac</h5>
                  <span>Director</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                  "Our company has been struggling to keep up with the pace of
                    technological change, but this IT company provided the
                    solutions we needed to catch up. The DevOps services helped
                    us streamline our software development process, while the
                    blockchain solutions offered the security we needed for our
                    financial transactions."
                  </p>
                  <div className="reviewer">
                    <img src={process.env.PUBLIC_URL + "/img/reivewer-1.jpg"} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Abishek Yogeshwaran</h5>
                  <span>Executive Chairman</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                  "We were hesitant about moving our operations to the cloud,
                    but this IT company made the transition seamless. Their
                    cloud services have given us the scalability and flexibility
                    we need to grow our business, while their cybersecurity
                    solutions have kept our data safe and secure."
                  </p>
                  <div className="reviewer">
                    <img src={process.env.PUBLIC_URL +"/img/reivewer-2.jpg"} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Jesmin korac</h5>
                  <span>Director</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                  "We've been working with this IT company for several years
                    now and their expertise in AI has been invaluable. Their
                    solutions have helped us gain insights into our customers'
                    behavior, enabling us to offer personalized experiences that
                    keep them coming back. Their commitment to excellence is
                    unparalleled and we couldn't be happier with the results."
                  </p>
                  <div className="reviewer">
                    <img src={process.env.PUBLIC_URL + "/img/reivewer-1.jpg"} alt="" />
                  </div>
                </div>
              </SwiperSlide>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default ClientTestimonial;
