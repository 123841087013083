import React, { useRef } from "react";
import emailjs from "@emailjs/browser";


function ContactWrapper() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_o29fi2u",
        "template_3ko97qd",
        form.current,
        "YkWlUb5bE8q5lDy9Z"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <section className="contact-area sec-mar">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-5">
              <div className="contact-left">
                <div className="sec-title layout2">
                  <span>Get in touch</span>
                  <h2>Contact us if you have more questions.</h2>
                </div>
                <ul className="social-follow">
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.twitter.com/"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.pinterest.com/"
                    >
                      <i className="fab fa-pinterest-p" />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                </ul>
                <div className="informations">
                  <div className="single-info">
                    <div className="icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <div className="info">
                      <h3>Location</h3>
                      <p>
                        12100 Ford RD, Suite B115, Farmers Branch Texas 75234
                      </p>
                    </div>
                  </div>
                  <div className="single-info">
                    <div className="icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="info">
                      <h3>Phone</h3>
                      <a href="tel:05661111985">+(972) 908-9249</a>
                      {/* <a href="tel:06571111576">+880 657 1111 576</a> */}
                    </div>
                  </div>
                  <div className="single-info">
                    <div className="icon">
                      <i className="far fa-envelope" />
                    </div>
                    <div className="info">
                      <h3>Email</h3>
                      <a href="mailto:info@viasdlc.com">info@viasdlc.com</a>
                      {/* <a href="tell:info@support.com">info@support.com</a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-7">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2621.1186979120703!2d-96.89263570887482!3d32.9127198342301!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c27bd6956900d%3A0x2f144af315f84bd3!2s12100%20Ford%20Rd%20b115%2C%20Farmers%20Branch%2C%20TX%2075234%2C%20USA!5e1!3m2!1sen!2sin!4v1682911202836!5m2!1sen!2sin"></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="getin-touch">
            <div className="row">
              <div className="col-md-6 col-lg-7">
                <div
                  className="contact-form wow animate fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms"
                >
                  <h3>Have Any Questions</h3>
                  <form ref={form} onSubmit={sendEmail}>
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="text" 
                          name="user_name"
                          placeholder="Enter your name"
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="email"
                          name="user_email"
                          placeholder="Enter your email"
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="subject"
                          placeholder="Subject"
                        />
                      </div>
                      <div className="col-12">
                        <textarea
                          name="message"
                          cols={30}
                          rows={10}
                          placeholder="Your message"
                          defaultValue={""}
                        />
                        <input type="submit" value="Send" defaultValue="Send Message" onClick={sendEmail}/>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-5 wow animate fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="call-banner">
                  <img
                    src={process.env.PUBLIC_URL + "/img/call-center.png"}
                    alt="images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactWrapper;
