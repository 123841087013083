import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

function SubscribArea() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_eheaawh', 'template_4jzzzil', form.current, 'PoRSVvFQsXXE3_tGU')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <>
      <section className="subscribe-newsletter sec-mar-top">
        <div className="container">
          <div className="news-letter-content">
            <div className="row align-items-center">
              <div
                className="col-lg-6 wow animate fadeInLeft"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className="subscribe-cnt">
                  <span>Enquire about our Service</span>
                  <h3>Send Us Message</h3>
                  <strong>We will get back to you soon!</strong>
                </div>
              </div>
              <div
                className="col-lg-6 wow animate fadeInRight"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className="subscribe-form">
                  <form ref={form} onSubmit={sendEmail}>
                    {/* <label>Name</label> */}
                    <input type="text" name="user_name" placeholder="Name"/>
                    {/* <label>Email</label> */}
                    <input type="email" name="user_email" placeholder="Email"/>
                    {/* <label>Message</label> */}
                    <textarea name="message" placeholder="Message"/>
                    <input type="submit" value="Send" onSubmit={sendEmail} />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SubscribArea;