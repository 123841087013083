import React from "react";
import { Link, useParams } from "react-router-dom";
function ServiceDetailsWrap() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  let { servicename } = useParams();

  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/blog-thumnail.jpg"}
                  alt="images service"
                />
                <h3>
                  <i>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/icons/service-details-icon.png"
                      }
                      alt="service icon images"
                    />
                  </i>
                  Cyber Security
                </h3>
                <p>
                  We provide comprehensive solutions to protect your digital
                  assets from malicious attacks. In today's fast-paced digital
                  world, data breaches and cyber attacks are becoming more
                  sophisticated and common. Therefore, it's essential to ensure
                  that your systems and networks are secure and well-protected.
                  Our company is dedicated to delivering the highest level of
                  cybersecurity services to businesses of all sizes.
                </p>
                <p>
                  Our team of cybersecurity experts has extensive knowledge and
                  experience in identifying and mitigating cyber threats. We
                  provide a range of services, including vulnerability
                  assessments, penetration testing, network security, and
                  incident response. Our goal is to help our clients understand
                  and manage their security risks, and to provide them with the
                  tools and expertise needed to protect their business.
                </p>
                <p>
                  At our cybersecurity company, we understand that security is a
                  top priority for businesses. We are committed to providing the
                  highest quality of service and support to help our clients
                  protect their digital assets and safeguard their business
                  operations. Contact us today to learn more about how we can
                  help you secure your digital environment.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-widget">
                <h4>Category</h4>
                <ul className="category">
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      AI & IOT
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Cyber Security
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      DevOps & Automation
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Cloud Services
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      BlockChain Technology
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Staffing or Recruitment
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sidebar-banner">
                <img
                  src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"}
                  alt="Banner images"
                />
                <div className="banner-inner">
                  <h3>
                    Any Project <span>Call Now.</span>
                    <img
                      className="angle"
                      src={process.env.PUBLIC_URL + "/img/arrow-angle.png"}
                      alt=""
                    />
                  </h3>
                  <a href="tel:11231231234">+1-123-123-1234</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap;
