import React from "react";
import { Link, useParams } from "react-router-dom";
function ServiceDetailsWrap() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  let { servicename } = useParams();

  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/blog-thumnail.jpg"}
                  alt="images service"
                />
                <h3>
                  <i>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/icons/service-details-icon.png"
                      }
                      alt="service icon images"
                    />
                  </i>
                  Staffing or Recruitment
                </h3>
                <p>
                  We specialize in providing top-quality staffing solutions to
                  businesses across a wide range of industries. Our team of
                  experienced recruiters is dedicated to finding the right
                  talent to help your business grow and succeed.
                </p>
                <p>
                  We understand that finding the right employees can be a
                  time-consuming and difficult process. That's why we're here to
                  take the burden off your shoulders and streamline the hiring
                  process for you. Whether you need temporary staff to fill in
                  for a short-term project, or you're looking for a permanent
                  addition to your team, we have the expertise and resources to
                  meet your needs.
                </p>
                <p>
                  At our staffing and recruitment company, we're committed to
                  delivering exceptional service and building long-lasting
                  relationships with our clients. Contact us today to learn more
                  about how we can help your business thrive.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-widget">
                <h4>Category</h4>
                <ul className="category">
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      AI & IOT
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Cyber Security
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      DevOps & Automation
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Cloud Services
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      BlockChain Technology
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Staffing or Recruitment
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sidebar-banner">
                <img
                  src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"}
                  alt="Banner images"
                />
                <div className="banner-inner">
                  <h3>
                    Any Project <span>Call Now.</span>
                    <img
                      className="angle"
                      src={process.env.PUBLIC_URL + "/img/arrow-angle.png"}
                      alt=""
                    />
                  </h3>
                  <a href="tel:11231231234">+1-123-123-1234</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap;
