import React from "react";
import { Link } from "react-router-dom";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import Breadcrumb from "../../common/Breadcrumb";
import "./careers.css";

SwiperCore.use([Navigation, Autoplay]);

function Careers() {
  return (
    <>
      <Breadcrumb pageName="Careers" pageTitle="Careers" />
      <CareerContent />
    </>
  );
}

export default Careers;

function CareerContent(props) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const serviceSlider = {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    slidesPerView: 3,
    spaceBetween: 30,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };
  return (
    <>
      <section className={`${"services-area sec-mar"} ${props.layoutClass}`}>
        <div className="container">
          <div
            className="title-wrap wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className={`${"sec-title"} ${props.white}`}>
              <span>Our Careers</span>
              <h2>Job Openings</h2>
              <p>
                Join our team at ViaSDLC, a leading IT company dedicated to
                innovation and reliable solutions for businesses worldwide.
              </p>
            </div>
          </div>
          <Swiper {...serviceSlider} className="swiper services-slider">
            <div className="swiper-wrapper">
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>01</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-1.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Blockchain Developer</h4>
                  <p>
                    We are seeking a skilled Blockchain Developer to join our
                    team and assist in developing and implementing
                    blockchain-based solutions for our clients. The ideal
                    candidate will have 2+ years of experience in developing
                    decentralized applications, smart contracts, and building on
                    blockchain platforms such as Ethereum and Hyperledger.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/careers/block`}
                      onClick={scrollTop}
                    >
                      Apply
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>02</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-2.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Cybersecurity Analyst</h4>
                  <p>
                    We are seeking a skilled Cybersecurity Analyst to join our
                    team and assist in providing cybersecurity solutions for our
                    clients. The ideal candidate will have 2+ years of
                    experience in cybersecurity, with a strong understanding of
                    risk management, threat detection, and incident response.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/careers/cyber`}
                      onClick={scrollTop}
                    >
                      Apply
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              {/* <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>03</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Cloud Solutions Architect</h4>
                  <p>
                    We are looking for an experienced Cloud Solutions Architect
                    to join our team and help design and implement cloud-based
                    solutions for our clients. The ideal candidate will have 5+
                    years of experience in cloud architecture, with a strong
                    understanding of cloud platforms such as AWS, Azure, and
                    Google Cloud.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Apply
                    </Link>
                  </div>
                </div>
              </SwiperSlide> */}
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>04</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>DevOps Engineer</h4>
                  <p>
                    We are seeking a skilled DevOps Engineer to join our team
                    and help automate and streamline our clients' development
                    and operations processes. The ideal candidate will have 3+
                    years of experience in DevOps, with a strong understanding
                    of continuous integration/continuous deployment (CI/CD) and
                    infrastructure as code (IaC).
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/careers/dev`}
                      onClick={scrollTop}
                    >
                      Apply
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>06</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Staffing Specialist</h4>
                  <p>
                    We are looking for an experienced Staffing Specialist to
                    join our team and help manage the hiring process for our
                    clients. The ideal candidate will have 3+ years of
                    experience in recruitment, with a strong understanding of
                    the IT industry and its talent requirements.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/careers/staff`}
                      onClick={scrollTop}
                    >
                      Apply
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>
    </>
  );
}
